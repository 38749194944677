import React from 'react';
import { SbBlokData } from 'gatsby-source-storyblok';
import { Box, ThemeContext } from 'grommet';

interface Props {
  blok: SbBlokData;
}

type LineSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

const DefaultDivider = ({ size }: { size: LineSize }) => (
  <Box border={{ side: 'top', size }} width="100%"></Box>
);

const Line = ({
  color,
  size,
  last = false,
}: {
  color: string;
  size: LineSize;
  last: boolean;
}) => {
  const lineSize = '4px';
  return (
    <Box
      width="100%"
      border={{ side: 'bottom', size, color }}
      height={lineSize}
      margin={last ? undefined : { bottom: lineSize }}
    />
  );
};

const PorscheDivider = ({ size }: { size: LineSize }) => {
  return (
    <Box width="100%">
      <Line color="porscheBlue" size={size} />
      <Line color="porscheMagenta" size={size} />
      <Line color="porscheYellow" size={size} />
      <Line color="porscheGreen" size={size} last />
    </Box>
  );
};

export default function DynamicHorizontalDivider({ blok }: Props) {
  const { theme, lineSize = 'small' } = blok;
  return theme === 'porsche' ? (
    <PorscheDivider size={lineSize} />
  ) : (
    <DefaultDivider size={lineSize} />
  );
}
